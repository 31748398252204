//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ImporterModel } from '@/modules/importer/importer-model';
import { routerAsync } from '@/app-module';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

import ImporterErrorModal from '@/modules/importer/components/importer-error-modal';
import ImporterSuccessfullyModal from '@/modules/importer/components/importer-successfully-modal';
import ImporterFormPrint from '@/modules/importer/components/importer-form-print';
import VueBarcode from 'vue-barcode';

const { fields } = ImporterModel; 
const formSchema = new FormSchema([
  // fields.id,
  fields.itemId,
  fields.itemType,
  fields.serialNumber,
  fields.qrCode,
  fields.warrantyStatus,
  fields.status,
  fields.activationDate,
  fields.expirationDate,
  fields.activatedBy,
]);

//#region [ Zebra Printer ]
// import * as BrowserPrint from 'https://firebasestorage.googleapis.com/v0/b/puricom-egypt-staging.appspot.com/o/js%2FBrowserPrint-3.0.216.min.js?alt=media&token=fd62f783-b8ff-4b34-b15b-9fb3f1e7a089'
import * as BrowserPrint from '../BrowserPrint-3.0.216.min.js'
var selected_device;
var devices = [];
function setup() {
	//Get the default device from the application as a first step. Discovery takes longer to complete.
	BrowserPrint.getDefaultDevice("printer", function(device) {
		//Add device to list of devices and to html select element
		selected_device = device;
		devices.push(device);
		var html_select = document.getElementById("selected_device");
		var option = document.createElement("option");
		option.text = device.name;
		html_select.add(option);
		
		//Discover any other devices available to the application
		BrowserPrint.getLocalDevices(function(device_list){
			for(var i = 0; i < device_list.length; i++)
			{
				//Add device to list of devices and to html select element
				var device = device_list[i];
				if(!selected_device || device.uid != selected_device.uid)
				{
					devices.push(device);
					var option = document.createElement("option");
					option.text = device.name;
					option.value = device.uid;
					html_select.add(option);
				}
			}
			
		}, function(){alert("Error getting local devices")},"printer");
		
	}, function(error){
		alert(error);
	})
}
// function getConfig(){
// 	BrowserPrint.getApplicationConfiguration(function(config){
// 		alert(JSON.stringify(config))
// 	}, function(error){
// 		alert(JSON.stringify(new BrowserPrint.ApplicationConfiguration()));
// 	})
// }
function writeToSelectedPrinter(dataToWrite) {
  selected_device.send(dataToWrite, undefined, errorCallback);
}
var errorCallback = function(errorMessage) {
	alert("Error: " + errorMessage);	
}
// var readCallback = function(readData) {
// 	if(readData === undefined || readData === null || readData === "") {
// 		alert("No Response from Device");
// 	}	else {
// 		alert(readData);
// 	}
// }
// function readFromSelectedPrinter() {
// 	selected_device.read(readCallback, errorCallback);
// }
// function getDeviceCallback(deviceList) {
// 	alert("Devices: \n" + JSON.stringify(deviceList, null, 4))
// }
// function sendImage(imageUrl) {
// 	var url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
// 	url = url + "/" + imageUrl;
// 	selected_device.convertAndSendFile(url, undefined, errorCallback)
// }
// function sendFile(fileUrl) {
//     var url = window.location.href.substring(0, window.location.href.lastIndexOf("/"));
//     url = url + "/" + fileUrl;
//     selected_device.sendFile(url, undefined, errorCallback)
// }
// function onDeviceSelected(selected) {
// 	for(var i = 0; i < devices.length; ++i){
// 		if(selected.value == devices[i].uid) {
// 			selected_device = devices[i];
// 			return;
// 		}
// 	}
// }
// window.onload = setup;
//#endregion


export default {
  name: 'app-factory-form',
  props: ['isEditing'],
  
  components: {
    [ImporterErrorModal.name]: ImporterErrorModal,
    [ImporterSuccessfullyModal.name]: ImporterSuccessfullyModal,
    [ImporterFormPrint.name]: ImporterFormPrint,
    'barcode': VueBarcode,
  },

  data() {
    return {
      step: 1,
      tab: 'product',
      model: null,
      numberOfItems: undefined,
      printingCountPerItem: undefined,
      active: false,
      itemId: undefined,
      options: [],
      printingLoading: false,
      // importedItemCount: 0,
      serialNumberTest: undefined,
      qrCodeTest: undefined,
      dialogVisible: false,
      errorModalVisible: false,
      successDialogVisible: false,
      successModalVisible: false,
      rules: formSchema.rules(),
      // language: getLanguageCode(),
    };
  },

  async created() {
    // await firebase.firestore().collection('items').onSnapshot(async querySnapshot => {
    //    console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //    this.importedItemCount = querySnapshot.size
    // }, err => {
    //    console.log(`Encountered error: ${err}`);
    // });

    await this.doFindSettings()

    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record || {});

    this.model.itemType = this.tab
    this.model.warrantyStatus = 'inactive'
    this.model.status = 'inFactory'
    // await this.addScript('../ebapi-modules.js')
    // await this.addScript('../ebapi.js')
    // await this.addScript('../eb.printer.js')
    // await this.addScript('https://firebasestorage.googleapis.com/v0/b/puricom-egypt-staging.appspot.com/o/js%2FBrowserPrint-3.0.216.min.js?alt=media&token=fd62f783-b8ff-4b34-b15b-9fb3f1e7a089')
    // setup()
  },
  async mounted() {
    setup()
    await this.doFetchImportedItems({ filter: { status: 'inFactory' } })
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      product: 'product/list/rows',
      sparePart: 'sparePart/list/rows',
      accessory: 'accessory/list/rows',
      productLoading: 'product/list/loading',
      sparePartLoading: 'sparePart/list/loading',
      accessoryLoading: 'accessory/list/loading',
      importedItemCount: 'importer/list/count',

      settings: 'settings/settings',

      record: 'importer/form/record',
      // findLoading: 'importer/form/findLoading',
      saveLoading: 'importer/form/saveLoading',
      serialNumbers: 'importer/serialNumber/rows',
      serialNumbersLoading: 'importer/serialNumber/loading',
    }),
    language(){
      return i18n.locale
    },
    barcodeTitle(){
      return this.settings.barcodeTitle || ''
    },
    loadingItems() {
      return this.productLoading || this.sparePartLoading || this.accessoryLoading
    },
    fields() {
      return fields;
    },
    type() {
      return this.tab
    },
    rows() {
      let list = []
      switch (this.type) {
        case 'product':
          list = this.product
          break;
        case 'sparePart':
          list = this.sparePart
          break;
        case 'accessory':
          list = this.accessory
          break;
        default:
          break;
      } 
      return list
    },
    itemList() {
      return this.rows.map(item => { return { label: item.name, value: item.id } })
    }
  },

  methods: {
    ...mapActions({
      doFind: 'importer/form/doFind',
      doNew: 'importer/form/doNew',
      doCreate: `importer/form/doCreate`,
      doUpdate: `importer/form/doUpdate`,
      doFetchSerial: 'importer/serialNumber/doFetchSerial',
      doUpdateSerialAsUsed: 'importer/serialNumber/doUpdateSerialAsUsed',
      doFetchImportedItems: 'importer/list/doFetchInBackground',
      doFindSettings: 'settings/doFind',
    }),
    doReset() {
      this.model = formSchema.initialValues(this.record || {});
      this.model.warrantyStatus = 'inactive'
      this.model.status = 'inFactory'
      this.numberOfItems = undefined;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doOpenModal(type) {
      switch (type) {
        case 'error':
          this.errorModalVisible = true
          this.errorDialogVisible = true;
          break;
        case 'success':
          this.successModalVisible = true
          this.successDialogVisible = true;
          break;
      }
    },
    onModalClose() {
      this.errorDialogVisible = false;
      this.successDialogVisible = false;
      setTimeout(() => {
        this.errorModalVisible = false
        this.successModalVisible = false
      }, 500);
    },
    doClearInput(inputName) {
      switch (inputName) {
        case 'serialNumberTest':
          this.serialNumberTest = undefined
          break;
        case 'qrCodeTest':
          this.qrCodeTest = undefined
          break;
        default:
          break;
      }
    },
    OnItemChange() {
      let item = this.rows.filter(el => el.id == this.itemId) 
      this.model.qrCode = item.length ? item[0].qrCode : undefined;
    },
    OnClear() {
      this.model.qrCode = undefined
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.options = this.itemList
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.options = this.itemList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    
    prev() { this.step > 1 ? this.step-- : this.$emit('cancel') },
    
    async next() { 
      if (this.step == 1) {
        await this.doFetchSerial(parseInt(this.numberOfItems))
      } 
      this.step++ 
    },
    
    doCancel() {
      this.$emit('cancel');
    },
    addScript(link){
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', link);
      document.head.appendChild(recaptchaScript);
    },

    // printBarcodes() {
    //   setup()    
    //   this.printingLoading = true
    //   for (let index = 0; index < this.serialNumbers.length; index++) {
    //     for (let i = 0; i < parseInt(this.printingCountPerItem); i++) {
    //       const element = this.serialNumbers[index];
    //       let title = 'Puricom Egypt'
    //       let barcode = `^XA^FO135,20^CFD^FD${title}  #${index+1}^FS^FO45,50^BY2^BCN,100,Y,N,N^FD${element.serialNumber}^FS^XZ`
    //       writeToSelectedPrinter(barcode)
    //     }

    //     if (parseInt(this.printingCountPerItem) > 1) {
    //       let label_separator = `^XA^C128^FO80,80^A@N,30,20^FDProduct #${index+1}^FS^XZ`
    //       writeToSelectedPrinter(label_separator)
    //     }
    //   }

    //   this.printingLoading = false
    //   return true
    // },

    printItems() {
      setup()    
      this.printingLoading = true

      const serialNumbers = this.serialNumbers.map(el => el.serialNumber)
      const printingCount = parseInt(this.printingCountPerItem)
      this.printBarcodePerItem(serialNumbers, printingCount, 0)

      this.printingLoading = false
      return true
    },

    printBarcodePerItem(serialNumbers, printingCountPerItem, index) {
      if (serialNumbers.length == index) return
      const serialNumber = serialNumbers[index];
      // const title = 'Puricom Egypt'
      const barcodeTitle = this.settings ? this.settings.barcodeTitle : ''
      const barcode = `^XA^FO80,40^CFD^FD${barcodeTitle}^FS^FO40,70^BY2^BCN,100,Y,N,N^FD${serialNumber}^FS^XZ`
      // const barcode = `^XA^FO840^CFD^FD${this.barcodeTitle}^FS^FO40,70^BY2^BCN,100,Y,N,N^FD${serialNumber}^FS^XZ`
      // const barcode = `^XA^FO135,20^CFD^FD${barcodeTitle}  #${index+1}^FS^FO45,50^BY2^BCN,100,Y,N,N^FD${serialNumber}^FS^XZ`
      // let label_separator = `^XA^C128^FO80,80^A@N,30,20^FDProduct #${index+1}^FS^XZ`
      // writeToSelectedPrinter(label_separator)
      // if (printingCountPerItem > 1) {
      // }

      for (let i = 0; i < printingCountPerItem; i++) {
        writeToSelectedPrinter(barcode)
      }
      let factor = printingCountPerItem < 10 ? 2000 * (printingCountPerItem + 1) : 10000
      setTimeout(() => {
        this.printBarcodePerItem(serialNumbers, printingCountPerItem, index+1)     
      }, factor);
 
      // this.sleep(1000 * (printingCountPerItem + 1)).then(() => {
      //   return this.printBarcodePerItem(serialNumbers, printingCountPerItem, index+1)
      // });

      // return this.printBarcodePerItem(serialNumbers, printingCountPerItem, index+1)


      // new Promise(this.print(barcode, printingCountPerItem)).then(() => {
      //   return this.printBarcodePerItem(serialNumbers, printingCountPerItem, index+1) 
      // })
    },

    // print(barcode, count) {
    //   for (let i = 0; i < count; i++) {
    //     writeToSelectedPrinter(barcode)
    //   }
    // },

    sleep(ms) {
      return new Promise(
        resolve => setTimeout(resolve, ms)
      );
    },
    async doSubmit() {
      if (this.step != 3) {
        return this.next();
      }
      
      const printState = this.printItems()
      if (printState) {
        const { ...data } = formSchema.cast(this.model);
        const values = {
          model: data,
          itemType: this.type,
          itemId: this.itemId,
          serialNumbers: this.serialNumbers,
          numberOfItems: parseInt(this.numberOfItems),
        }
        await this.doCreate(values);
        routerAsync().push('/factory')
      }
    },
  },
  watch: {
    itemId() {
      if (this.itemId) {
        let item_selected = this.rows.filter(el => el.id == this.itemId)[0]
        this.model.itemId = item_selected.id
        this.model.qrCode = item_selected ? item_selected.qrCode : undefined;
      } else {
        this.model.itemId = undefined
        this.model.qrCode = undefined
      }
    },
    tab() {
      this.model.itemId = undefined
      this.itemId = undefined
      this.numberOfItems = undefined
      this.model.itemType = this.tab
    }
  },
};
