//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import factoryForm from '@/modules/factory/components/factory-form';
import { routerAsync } from '@/app-module';
import  i18n  from '@/vueI18n';

export default {
  name: 'app-factory-form-page',

  props: ['id'],

  components: {
    [factoryForm.name]: factoryForm,
  },

  data() {
    return {
      tab: 'product',
    }
  },
  computed: {
    ...mapGetters({
      // record: 'importer/form/record',
      // findLoading: 'importer/form/findLoading',
      // saveLoading: 'importer/form/saveLoading',
    }),
    findLoading() {
      return this.$store.getters[
        `${this.type}/form/findLoading`
      ];
    },
    saveLoading() {
      return this.$store.getters[
        `${this.type}/form/saveLoading`
      ];
    },
    isEditing() {
      return !!this.id;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.factory') +
            ' | ' +
            this.i18n('entities.factory.new.title') 
    },
  },

  async created() {
    this.doFetchProduct()
    this.doFetchSparePart()
    this.doFetchAccessory()
  },
  methods: {
    ...mapActions({
      // doFind: `${this.tab}/form/doFind`,
      // doNew: `${this.tab}/form/doNew`,
      doCreate: `importer/form/doCreate`,
      doUpdate: `importer/form/doUpdate`,
      doFetchProduct: 'product/list/doFetch',
      doFetchSparePart: 'sparePart/list/doFetch',
      doFetchAccessory: 'accessory/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    // doUpdate(payload) {
    //   return this.$store.dispatch(
    //     `${this.tab}/form/doUpdate`, payload
    //   );
    // },
    doCancel() {
      routerAsync().push('/factory');
    },
    async doSubmit(payload) {
      return this.doCreate(payload);
    },
  },
};
